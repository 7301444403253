<!--
 * @Author: your name
 * @Date: 2021-05-13 18:20:04
 * @LastEditTime: 2021-05-19 19:38:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one2\src\components\news\index.vue
-->
<template>
  <div @click="tomsg" class="allmsgclass3">
    <img
      v-if="msg == 'white'"
      class="svgclass"
      src="../../assets/whitemsg.png"
      alt=""
    />
    <img
      v-if="msg == 'black'"
      class="svgclass"
      src="../../assets/blackmsg.png"
      alt=""
    />
    <div
      v-if="ishavemsg == true"
      :class="msg == 'white' ? 'dian' : 'dian2'"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      ishavemsg: false, //是否有未读消息
    };
  },
  mounted() {
    this.$http
      .post("/firm/v1/Index/getMessageList", {
        reqType: "userinfo",
        id: localStorage.getItem("userId"),
        type: "",
        status: 0, //状态 0未读 1已读
        start: "",
        end: "",
        pageno: 1,
        limit: 15,
        content: "",
      })
      .then((res) => {
        let arraymsg = JSON.parse(res.data).data.messageList;
        if (arraymsg.length > 0) {
          this.ishavemsg = true;
        } else {
          this.ishavemsg = false;
        }
      });
  },
  methods: {
    tomsg() {
      this.$router.push("/stationletter");
    },
  },
};
</script>
<style scoped>
.dian {
  position: absolute;
  top: -0.05rem;
  right: -0.05rem;
  width: 0.2rem;
  height: 0.2rem;
  background-color: #ffffff;
  border-radius: 50%;
}
.dian2 {
  position: absolute;
  top: -0.05rem;
  right: -0.05rem;
  width: 0.2rem;
  height: 0.2rem;
  background-color: #f65c0e;
  border-radius: 50%;
}
.allmsgclass3 {
  position: relative;
  display: flex;
  align-items: center;
}
.allmsgclass3 .svgclass {
  width: 0.5rem;
}
</style>
