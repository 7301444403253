<!--
 * @Author: your name
 * @Date: 2021-01-15 10:48:38
 * @LastEditTime: 2021-05-13 18:29:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\home2\index.vue
-->
<template>
  <div class="allmsgclass">
    <!-- 下面div是我写的  范文政 -->
    <!-- <div style="height:100px" @click="toPage">123123</div> -->

    <div class="topmsgclass">
      <div class="kefuimg">
        <img @click="clickkefu" src="../../assets/kefu2.png" />
        <newses msg="white"></newses>
      </div>
      <div class="top1">
        <div class="topleftclass">
          <div class="beicenter">
            {{
              company == "" || company == undefined
                ? "萝卜猎手雇前背景调查中心"
                : company
            }}
          </div>
          <div class="allduiclass">
            <div class="duiclass">
              <img src="../../assets/dui.png" />
              <div>合规用工</div>
            </div>
            <div class="duiclass duiclass2">
              <img src="../../assets/dui.png" />
              <div>权威精准</div>
            </div>
            <div class="duiclass">
              <img src="../../assets/dui.png" />
              <div>多快好省</div>
            </div>
          </div>
        </div>
        <div class="topright">
          <div>最近完成</div>
          <div class="thespanclass">
            <span>{{ Number(beilength) > 99 ? "99+" : beilength }}</span
            ><span>次</span><span @click="$router.push('record')">背调</span
            ><van-icon
              @click="$router.push('record')"
              class="theicclass"
              size=".3rem"
              name="arrow"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mainclass">
      <div @click="liaojiemeth" class="theke">
        <div class="wanclass">玩转<span>萝卜猎手</span></div>
        <div class="jinaclass">
          <div>快速识别简历真伪，降低80%的</div>
          <div>用工风险，保护企业不受伤</div>
        </div>
        <button class="bton1">马上了解</button>
      </div>
      <div class="menutop">
        <div @click="$router.push('/sample')" class="mecla">
          <div>背景调查</div>
          <div>
            <div>报告样例看</div>
            <div>一看</div>
          </div>
        </div>
        <div @click="$router.push('/safeguard')" class="mecla">
          <div>用工保障</div>
          <div>
            <div>真有后悔药</div>
            <div>试一试</div>
          </div>
        </div>
        <div @click="$router.push('/makemoney')" class="mecla">
          <div>简历转介绍</div>
          <div>
            <div>企业招聘</div>
            <div>零成本</div>
          </div>
        </div>
      </div>
      <div class="liuclass">使用流程</div>
      <div class="toptheclass">
        <div class="clone">
          <div><img src="../../assets/onetop2.png" /></div>
          <div class="thechanmsg thechanmsg3">
            <div>认证准备</div>
            <div>公司营业执照</div>
          </div>
        </div>
        <img id="nextclass" src="../../assets/next.png" alt="" />
        <div class="clone">
          <div><img src="../../assets/twotop2.png" /></div>
          <div class="thechanmsg thechanmsg3">
            <div>点击底部“我的”</div>
            <div>完成企业认证</div>
          </div>
        </div>
        <img id="nextclass" src="../../assets/next.png" alt="" />
        <div class="clone">
          <div><img src="../../assets/threetop2.png" /></div>
          <div class="thechanmsg thechanmsg3">
            <div>联系客服领取</div>
            <div>免费背调和简历</div>
          </div>
        </div>
      </div>
    </div>
    <div class="botmsgclass">
      <div class="myc">
        <div>我们的<span>产品</span></div>
        <div @click="$router.push('/backtotwo')" class="moreclass">
          更多<van-icon name="arrow" />
        </div>
      </div>

      <div
        @click="$router.push('postfive?id=' + item.id)"
        v-for="(item, index) in homemsg"
        v-bind:key="index"
        class="chanpinclass"
      >
        <div class="chanimg"><img :src="item.cover" /></div>
        <div class="chanrightclass">
          <div>{{ item.set_meal_name }}</div>
          <div class="now">{{ item.fit_type }}</div>
          <div>
            <div></div>
            <!-- <van-icon
              class="theicclass"
              color="#979797"
              size=".3rem"
              name="arrow"
            /> -->
          </div>
          <div>{{ item.fit_post }}</div>
          <!-- <div>
            <div class="yjcla">
              <div>{{ item.price }}</div>
              <div class="xiaos">月售{{ item.new_sales }}份</div>
            </div>
          </div> -->
          <div>
            <div class="yjcla">
              <div><span>¥</span>{{ " " + item.price }}</div>
              <div class="oldmoney">
                {{ "¥ " + item.reference_price }}
              </div>
            </div>
            <div class="xiangqingclass">
              <!-- <div>详情</div>
                      <van-icon size=".25rem" color="#FE5903" name="arrow" /> -->
              <div class="xiaos">月售{{ item.new_sales }}份</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottclass">
      <div class="our">关于我们</div>
      <div class="ourimgclass">
        <div class="clone">
          <div><img src="../../assets/my1.png" /></div>
          <div>
            <div class="thechanmsg">权威精准</div>
            <div class="thechanmsg2">
              <div>对接国家级数据库</div>
              <div>世界背调协会</div>
              <div>NAPBS成员</div>
            </div>
          </div>
        </div>
        <div class="clone">
          <div><img src="../../assets/my2.png" /></div>
          <div>
            <div class="thechanmsg">产品丰富</div>
            <div class="thechanmsg2">
              <div>5年老品牌</div>
              <div>10万老板和人事在</div>
              <div>使用我们的产品</div>
            </div>
          </div>
        </div>
        <div class="clone">
          <div><img src="../../assets/my3.png" /></div>
          <div>
            <div class="thechanmsg">便捷高效</div>
            <div class="thechanmsg2">
              <div>多场景，多终端</div>
              <div>背调报告秒出</div>
              <div>随时随地 尽在掌握</div>
            </div>
          </div>
        </div>
      </div>
      <div class="huobclass">
        <div>合作伙伴</div>
        <div @click="$router.push('/collaborate')">
          更多<van-icon
            class="theicclass"
            color="#FF4000"
            size=".3rem"
            name="arrow"
          />
        </div>
      </div>
      <div @click="$router.push('/collaborate')" class="hezuoimg">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item><img src="../../assets/hezuo1.png" /></van-swipe-item>
          <van-swipe-item><img src="../../assets/hezuo2.png" /></van-swipe-item>
          <van-swipe-item><img src="../../assets/hezuo3.png" /></van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div id="aa" class="luobocenter">致力于为中国1000万家企业用好人</div>
    <div class="nomsg2"></div>
    <kefu ref="kefuid"></kefu>
  </div>
</template>
<script>
import sharepage from "../../views/sharepage/index";
import kefu from "../../components/kefu/index";
import base from "../../../util/base";
import newses from "../../components/news/index";
export default {
  components: {
    kefu,
    newses,
    sharepage,
  },
  data() {
    return {
      beilength: "",
      company: "",
      topimg: "",
      homemsg: "", //首页推荐套餐信息
    };
  },
  created() {
    //获取企业信息
    this.getqiye();
    //获取首页推荐信息
    this.gethomemsg();
    // 分享
    this.tosharemeth();
  },
  mounted() {
    // 获取全年被调记录
    this.getbeilength();
  },
  methods: {
    // 测试用的  范文政写
    // toPage() {
    //   this.$router.push({
    //     name: "fanwenzheng",
    //     query: {},
    //   });
    // },
    tosharemeth() {
      let obj = JSON.stringify({
        sharetitle: "AI助力，帮助企业招对人、用好人",
        sharedesc: "10万HR都在用的免费招聘神器，一个就够了！",
        isshare: "1",
        share_phone: localStorage.getItem("phone"),
      });
      localStorage.setItem("shareobj", obj);
    },
    /**
      首页推荐信息
     */
    gethomemsg() {
      this.$http
        .post("/firm/v1/Product/setMealIndex", {
          reqType: "product",
        })
        .then((res) => {
          this.homemsg = JSON.parse(res.data).data.setMealList;
        });
    },
    liaojiemeth() {
      window.location =
        base.tourl + "/#/?invitecode=" + localStorage.getItem("phone");
    },
    getqiye() {
      this.$http
        .post("/firm/v1/userinfo/companyinfo", {
          reqType: "userinfo",
        })
        .then((res) => {
          this.company = JSON.parse(res.data).data.company;
        });
    },
    getbeilength() {
      this.$http
        .post("/firm/v1/Background/yearCount", {
          reqType: "Background",
        })
        .then((res) => {
          this.beilength = JSON.parse(res.data).data.yearCount;
        });
    },
    clickkefu() {
      this.$refs.kefuid.showmeth();
    },
  },
};
</script>
<style scoped>
.xiangqingclass {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  font-weight: 400;
  color: rgba(254, 89, 3, 0.75);
  line-height: 0.34rem;
}
.oldmoney {
  text-decoration: line-through;
  font-size: 0.24rem;
  font-weight: 400;
  color: #c0c4cc;
  line-height: 0.34rem;
  margin-left: 0.1rem;
}
.allmsgclass .thechanmsg3 {
  font-size: 0.24rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.36rem;
}
#nextclass {
  width: 0.62rem;
  height: 0.1rem;
  padding-bottom: 0.9rem;
}
.allmsgclass {
  width: 100%;
  overflow: hidden;
}
.smallclass {
  font-size: 0.25rem;
}
.xiaos {
  font-size: 0.22rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #b8b8b8;
  line-height: 0.3rem;
  margin-left: 0.18rem;
}
.yjcla {
  display: flex;
  align-items: center;
}
.moreclass {
  display: flex;
  align-items: center;
  color: #5a5a5a;
  font-size: 0.28rem;
  line-height: 0.4rem;
}
.nomsg2 {
  height: 2rem;
}
.luobocenter {
  text-align: center;
  font-size: 0.32rem;
  line-height: 0.45rem;
  color: #f55814;
  padding: 0.22rem;
  font-weight: bold;
}
.hezuoimg {
  margin-top: 0.4rem;
}
.hezuoimg img {
  width: 100%;
}
.huobclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.44rem;
}
.huobclass > div:nth-child(2) {
  font-size: 0.28rem;
  line-height: 0.4rem;
  color: #5a5a5a;
}
.huobclass > div:first-child {
  font-size: 0.4rem;
  line-height: 0.56rem;
  color: #222222;
  font-weight: bold;
}
.thechanmsg2 {
  font-size: 0.2rem;
  line-height: 0.3rem;
  color: #727272;
}
.thechanmsg {
  font-size: 0.24rem;
  line-height: 0.3rem;
  color: #222222;
  font-weight: bold;
  margin: 0.12rem 0;
}
.ourimgclass img {
  width: 0.9rem;
}
.ourimgclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.34rem;
}
.our {
  font-size: 0.4rem;
  line-height: 0.56rem;
  color: #222222;
  font-weight: bold;
}
.bottclass {
  border-radius: 0.2rem;
  background-color: #ffffff;
  margin: 0.32rem 0.22rem;
  padding: 0.25rem 0.34rem;
}
#nobot {
  margin-bottom: 0;
}
.now {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  min-height: 0.75rem;
}
.chanrightclass > div:nth-child(5) {
  font-size: 0.38rem;
  line-height: 0.45rem;
  color: #f55814;
  font-weight: bold;
  margin-top: 0.3rem;
  display: flex;
  justify-content: space-between;
}
.chanrightclass > div:nth-child(4) {
  font-size: 0.18rem;
  line-height: 0.25rem;
  color: #f55814;
  margin-bottom: 0.19rem;
  display: none;
}
.chanrightclass > div:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chanrightclass > div:nth-child(2) {
  color: #797979;
  font-size: 0.24rem;
  line-height: 0.38rem;
  margin: 0.08rem 0;
}
.chanrightclass > div:first-child {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #222222;
  font-weight: bold;
  width: 4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chanrightclass {
  width: 100%;
}
.chanimg {
  width: 2rem;
  min-width: 2rem;
  height: 2.08rem;
  margin-right: 0.29rem;
}
.chanimg img {
  width: 100%;
  height: 100%;
}
.chanpinclass {
  display: flex;
  margin: 0.33rem 0;
  border-radius: 0.2rem;
  overflow: hidden;
}
.myc span {
  color: #f55814;
}
.myc {
  font-size: 0.4rem;
  line-height: 0.56rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.botmsgclass {
  margin: 0.32rem 0.22rem;
  background-image: url("../../assets/beiimg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #ffffff;
  padding: 0.34rem 0.23rem;
}
.clone > div {
  text-align: center;
  margin-bottom: 0.12rem;
}
.toptheclass img {
  width: 0.9rem;
}
.toptheclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.liuclass {
  font-size: 0.3rem;
  line-height: 0.42rem;
  font-weight: bold;
  margin-bottom: 0.3rem;
}
.mecla {
  padding: 0.13rem 0.15rem;
}
.mecla > div:nth-child(2) > div {
  font-size: 0.24rem;
  line-height: 0.28rem;
  color: #8f8f8f;
}
.mecla > div:first-child {
  font-size: 0.32rem;
  line-height: 0.45rem;
  color: #222222;
  font-weight: bold;
  margin-bottom: 0.03rem;
}
.menutop > div:nth-child(3) {
  background-image: url("../../assets/menutop3.png");
  background-size: 100% 100%;
}
.menutop > div:nth-child(2) {
  background-image: url("../../assets/menutop2.png");
  background-size: 100% 100%;
}
.menutop > div:first-child {
  background-image: url("../../assets/menutop1.png");
  background-size: 100% 100%;
}
.menutop > div {
  width: 1.7rem;
  height: 1.12rem;
}
.menutop {
  margin-top: 0.31rem;
  margin-bottom: 0.4rem;
  display: flex;
  justify-content: space-between;
}
.bton1 {
  color: #ffffff;
  background: linear-gradient(to right, #f55613, #fc9e46);
  border: none;
  border-radius: 0.8rem;
  font-size: 0.24rem;
  line-height: 0.28rem;
  width: 1.71rem;
  padding: 0.11rem 0;
  margin-top: 0.3rem;
}
.jinaclass {
  font-size: 0.24rem;
  line-height: 0.34rem;
  color: #8f8f8f;
}
.wanclass span {
  color: #f55814;
}
.wanclass {
  font-size: 0.46rem;
  line-height: 0.65rem;
  font-weight: bold;
}
.theke {
  height: 2.1rem;
  background-image: url("../../assets/ke.png");
  background-size: 100% 100%;
  padding: 0.25rem 0.34rem;
}
.mainclass {
  margin: 0.22rem;
  padding: 0.22rem;
  background-color: #ffffff;
  border-radius: 0.2rem;
  margin-top: -0.5rem;
}
.duiclass2 {
  padding: 0 0.15rem;
  margin: 0 0.12rem;
  border-right-width: 1px;
  border-right-color: #ffffff;
  border-right-style: solid;
  border-left-width: 1px;
  border-left-color: #ffffff;
  border-left-style: solid;
}
.thespanclass {
  color: #ffffff;
  font-size: 0.24rem;
  line-height: 0.33rem;
  /* text-align: center; */
  white-space: nowrap;
}
.theicclass {
  transform: translateY(0.05rem);
}
.thespanclass > span:nth-child(3) {
  margin: 0 0.09rem;
}
.thespanclass > span:first-child {
  font-size: 0.6rem;
  line-height: 0.69rem;
  margin-right: 0.09rem;
}
.topright > div:first-child {
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #ffffff;
  /* padding-left: 0.23rem; */
}
/* .topright {
  width: 28%;
  overflow: auto;
} */
.topleftclass {
  padding-right: 0.2rem;
  margin-right: 0.1rem;
  border-right-color: #ffffff;
  border-right-style: solid;
  border-right-width: 1px;
}
.beicenter {
  width: 4.4rem;
  font-size: 0.36rem;
  line-height: 0.5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0.09rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.allduiclass {
  display: flex;
  align-items: center;
}
.duiclass img {
  width: 0.2rem;
  margin-right: 0.08rem;
}
.duiclass {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  line-height: 0.33rem;
  color: #ffffff;
}
.top1 {
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
}
.kefuimg {
  padding-top: 0.2rem;
  margin-bottom: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.kefuimg img {
  width: 1.23rem;
}
.topmsgclass {
  font-family: PingFangSC-Semibold, PingFang SC;
  background: linear-gradient(#ff4800, #ff7f00);
  padding: 1.23rem 0.3rem;
  padding-top: 0.3rem;
  box-shadow: 0rem 0.1rem 0.3rem #ff7700;
}
</style>
